import React from "react";

import { Row, Col, Container /* , Modal */ } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import AnimationContainer from "components/animation-container";
import Particles from "react-particles-js";

import { Trans, withTranslation } from "react-i18next";

// import Swal from "sweetalert2";

class Technologies extends React.Component {
  constructor() {
    super();
    this.state = { showModal: false };
  }

  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #071c2b;
            background-size: cover;
            padding: 100px 0;
            .heading {
                width: 100%;
            }
            .particles {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: #071c2b;
            }
          }
        `;

    const Heading = styled.h1`
      font-size: 70px;
      line-height: 70px;
      font-family: Teko;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      @media (min-width: 768px) and (max-width: 1399px) {
        font-size: 70px;
        line-height: 70px;
      }
      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 40px;
        text-align: center;
      }
    `;
    const Color = styled.span`
      color: #2ba0ea;
      font-size: 70px;
      line-height: 70px;
      font-family: Teko;
      text-transform: uppercase;
      letter-spacing: 2px;
      @media (min-width: 768px) and (max-width: 1399px) {
        font-size: 70px;
        line-height: 70px;
      }
      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 40px;
      }
    `;

    const LeftCol = styled(Col)`
      display: flex;
      align-items: center;
    `;

    return (
      <>
        {/* <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Modal</Modal.Title>
          </Modal.Header>
          <Modal.Body>Modal body content</Modal.Body>
        </Modal> */}
        <Section id="technologies">
          <Particles
            className="particles"
            params={{
              particles: {
                number: {
                  value: 100,
                  density: {
                    enable: true,
                    value_area: 2000,
                  },
                },
                color: {
                  value: ["#1C3258", "#2ba0ea", "#f4d22a"],
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#fff",
                  },
                },
                opacity: {
                  value: 0.5,
                  random: false,
                  anim: {
                    enable: true,
                    speed: 0.5,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 8.017060304327615,
                  random: true,
                  anim: {
                    enable: true,
                    speed: 12.181158184520175,
                    size_min: 0.1,
                    sync: true,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: this.context.theme === "dark" ? "#fff" : "#555",
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 1,
                  direction: "none",
                  random: false,
                  straight: false,
                  out_mode: "bounce",
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: {
                    enable: false,
                    mode: "repulse",
                  },
                  onclick: {
                    enable: false,
                    mode: "push",
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
            }}
          />
          <Container>
            <Row>
              <LeftCol md={6}>
                <Heading>
                  <Trans i18nKey={"technologies.heading"}>
                    <Color>Tecnolog&iacute;as</Color> en las que nos
                    especializamos
                  </Trans>
                </Heading>
              </LeftCol>
              <Col md={6}>
                <Row>{this.technologies()}</Row>
              </Col>
            </Row>
          </Container>
        </Section>
      </>
    );
  }

  technologies() {
    return this.props.technologies.map((value, index) => {
      const Technology = styled.img`
        height: 120px;
      `;

      const TechnologyCol = styled(Col)`
        text-align: center;
        padding: 20px 0;
        border-color: #efefef;
        border-left: none;
        border-top: none;
        transition: 0.1s;
        &:hover {
          transform: scale(1.1);
          background-color: #1c3258;
          z-index: 5;
          border-radius: 10px;
        }
        @media (max-width: 500px) {
          border: none !important;
        }
      `;

      return (
        <TechnologyCol
          md={4}
          key={index}
          style={{
            borderRight: (index + 1) % 3 === 0 ? "none" : "1px solid",
            borderBottom: index < 6 ? "1px solid" : "none",
          }}
        >
          <AnimationContainer animation="fadeIn slower" delay={index * 200}>
            <Technology
              src={value.content.frontmatter.image.childImageSharp.fluid.src}
              alt={value.content.frontmatter.name}
              onClick={() => {
                // this.setState({ showModal: true });
                // Swal.fire({
                //   customClass: {
                //     container: "border: 2px #FFFFFF",
                //   },
                //   showCloseButton: true,
                //   backdrop: "rgba(07, 28, 43, 0.85)",
                //   grow: "fullscreen",
                //   background: "#1C3258",
                //   iconColor: "rgba(0,0,0,0)",
                //   iconHtml: `<img
                //   src=${value.content.frontmatter.image.childImageSharp.fluid.src}
                //   alt=${value.content.frontmatter.name}
                //   width="88"
                //   />`,
                //   color: "#2ba0ea",
                //   titleText: value.content.frontmatter.name,
                //   text: value.content.frontmatter.text,
                //   showConfirmButton: false,
                // });
              }}
            />
          </AnimationContainer>
        </TechnologyCol>
      );
    });
  }
}

export default withTranslation()((props) => (
  <StaticQuery
    query={graphql`
      query {
        technologies: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(technologies)/" } }
          sort: { fields: [frontmatter___id], order: ASC }
        ) {
          edges {
            content: node {
              frontmatter {
                id
                name
                image {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ technologies }) => (
      <Technologies technologies={technologies.edges} {...props} />
    )}
  />
));
