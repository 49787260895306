import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import AnimationContainer from "components/animation-container";
import AnimatedHeading from "components/animated-heading";
import { withTranslation } from "react-i18next";

class Services extends React.Component {
  componentDidMount() {
    this.forceUpdate();
  }

  render() {
    const { t } = this.props;

    const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #071c2b;
            background-size: cover;
            .heading {
                width: 100%;
            }
          }
        `;

    const ServiceContainer = styled.div`
      background-color: #071c2b;
      padding: 100px 0;
    `;

    const ServiceElement = styled.div`
      margin-bottom: 20px;
      text-align: center;
      padding: 40px;
      border-radius: 20px;
      transition: 0.2s;
      &:hover {
        background-color: #1c3258;
        box-shadow: 0 28px 60px rgb(43, 160, 234, 0.2);
        transform: translateY(-10px);
      }
    `;
    const ServiceHeading = styled.h4`
      font-size: 30px;
      font-weight: 500;
      font-family: Teko;
      color: #fff;
    `;
    const ServiceSeparator = styled.div`
      height: 5px;
      width: 50px;
      background-color: #2ba0ea;
      margin-bottom: 10px;
      margin: auto;
    `;
    const ServiceIcon = styled.div`
      margin-bottom: 20px;
      img {
        max-height: 70px;
      }
    `;

    // const ServiceList = styled.ul`
    //   padding: 0;
    //   margin: 11px 0 0 0;
    // `;

    // const ServiceListElement = styled.li`
    //   list-style: none;
    //   color: #c5c5c5;
    //   font-weight: 300;
    //   font-size: 14px;
    //   margin: 5px 0px;
    //   @media (min-width: 961px) {
    //     font-size: 16px;
    //   }
    //   &::before {
    //     display: inline-block;
    //     content: "";
    //     border-radius: 2.5px;
    //     height: 5px;
    //     width: 5px;
    //     margin-right: 10px;
    //     background-color: #2ba0ea;
    //     position: relative;
    //     bottom: 2.5px;
    //   }
    // `;

    const ServiceText = styled.p`
      margin: 11px 0 0 0;
      font-size: 14px;
      font-weight: 300;
      color: #c5c5c5;
      @media (min-width: 961px) {
        font-size: 16px;
      }
    `;

    return (
      <Section id="services">
        <ServiceContainer>
          <Container>
            <AnimatedHeading text={t("services.heading")} />
            <Row className="justify-content-md-center">
              <Col md={4}>
                <AnimationContainer animation="fadeInLeft" delay={150}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={this.props.service1.childImageSharp.fluid.src}
                        alt="Cloud Computing"
                      />
                    </ServiceIcon>
                    <ServiceHeading>{t("services.heading1")}</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>{t("services.text1")}</ServiceText>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
              <Col md={4}>
                <AnimationContainer animation="fadeInDown" delay={300}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={this.props.service2.childImageSharp.fluid.src}
                        alt="CRM"
                      />
                    </ServiceIcon>
                    <ServiceHeading>{t("services.heading2")}</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>{t("services.text2")}</ServiceText>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
              <Col md={4}>
                <AnimationContainer animation="fadeInRight" delay={450}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={this.props.service3.childImageSharp.fluid.src}
                        alt="Marketing"
                      />
                    </ServiceIcon>
                    <ServiceHeading>{t("services.heading3")}</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>{t("services.text3")}</ServiceText>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
              <Col md={4}>
                <AnimationContainer animation="fadeInLeft" delay={600}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={this.props.service4.childImageSharp.fluid.src}
                        alt="Robot"
                      />
                    </ServiceIcon>
                    <ServiceHeading>{t("services.heading4")}</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>{t("services.text4")}</ServiceText>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
              <Col md={4}>
                <AnimationContainer animation="fadeInUp" delay={750}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={this.props.service5.childImageSharp.fluid.src}
                        alt="Datos"
                      />
                    </ServiceIcon>
                    <ServiceHeading>{t("services.heading5")}</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>{t("services.text5")}</ServiceText>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
              <Col md={4}>
                <AnimationContainer animation="fadeInRight" delay={900}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={this.props.service6.childImageSharp.fluid.src}
                        alt="Aplicaciones"
                      />
                    </ServiceIcon>
                    <ServiceHeading>{t("services.heading6")}</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>{t("services.text6")}</ServiceText>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
              <Col md={4}>
                <AnimationContainer animation="fadeInUp" delay={1050}>
                  <ServiceElement>
                    <ServiceIcon>
                      <img
                        src={this.props.service7.childImageSharp.fluid.src}
                        alt="Archivos"
                      />
                    </ServiceIcon>
                    <ServiceHeading>{t("services.heading7")}</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>{t("services.text7")}</ServiceText>
                  </ServiceElement>
                </AnimationContainer>
              </Col>
            </Row>
          </Container>
        </ServiceContainer>
      </Section>
    );
  }
}

export default withTranslation()((props) => (
  <StaticQuery
    query={graphql`
      query {
        service1: file(relativePath: { eq: "icons/service1.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        service2: file(relativePath: { eq: "icons/service2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        service3: file(relativePath: { eq: "icons/service3.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        service4: file(relativePath: { eq: "icons/service4.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        service5: file(relativePath: { eq: "icons/service5.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        service6: file(relativePath: { eq: "icons/service6.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        service7: file(relativePath: { eq: "icons/service7.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    `}
    render={({
      service1,
      service2,
      service3,
      service4,
      service5,
      service6,
      service7,
    }) => (
      <Services
        service1={service1}
        service2={service2}
        service3={service3}
        service4={service4}
        service5={service5}
        service6={service6}
        service7={service7}
        {...props}
      />
    )}
  />
));
