const es = {
  menu: {
    home: "Inicio",
    about: "Nosotros",
    services: "Servicios",
    solutions: "Soluciones",
    partners: "Partners",
    contact: "Contacto",
    account: "Mi cuenta",
    language: "Espa\u00F1ol",
    apigateway: "Dise\u00F1o de API",
  },
  home: {
    sub_title: "Consultores",
    typewriter: {
      word0: "Chatbots",
      word1: "Soluciones Integrales",
      word2: "Rob\u00F3tica",
      word3: "Agilidad",
      word4: "Seguridad",
      word5: "Cloud Computing",
      word6: "Business Intelligence",
      word7: "Aplicaciones M\u00F3viles",
      word8: "Inteligencia Artificial",
    },
    piller1: "Experiencia",
    content1:
      "Contamos con un talentoso equipo de expertos certificados en diferentes tecnolog\u00EDas de vanguardia.",
    piller2: "Agilidad",
    content2:
      "Nuestra experiencia y metodolog\u00EDa nos permiten brindarte soluciones integrales de manera r\u00E1pida y efectiva.",
    piller3: "Calidad",
    content3:
      "Gracias a nuestros procesos de aprendizaje y mejora continua, brindamos soluciones de la mejor calidad.",
  },
  about: {
    heading: "<0>Creadores</0> de <2>Soluciones</2> Tecnol\u00F3gicas",
    paragraph1: `Desde nuestra fundaci\u00F3n en 1995, hemos creado
      relaciones de confianza y fidelidad con nuestros clientes.
      Tenemos m\u00E1s de 20 a\u00F1os de experiencia trabajando
      con entidades financieras en la regi\u00F3n y otras
      organizaciones importantes. Ofrecemos soluciones para
      simplificar procesos por medio de diversos servicios.
      Desarrollamos aplicaciones web, tambi\u00E9n indexamos y
      almacenamos tus documentos en f\u00EDsico.`,
    paragraph2: `Nos orientamos a satisfacer las necesidades de tu empresa
      a trav\u00E9s de la innovaci\u00F3n y nuevas
      tendencias tecnol\u00F3gicas, para qu\u00E9 logres
      reducir tus costos operativos y aumentar tu eficiencia.
      Contamos con un equipo comprometido, trabajando con
      integridad y honestidad, lo cual ha formado parte de
      nuestra esencia. Aseguramos el mejor servicio y calidad en
      un producto personalizado, a la altura de tu empresa.`,
    number1: 80,
    sub_title1: "Certificaciones",
    number2: 25,
    sub_title2: "27 A\u00F1os de Experiencia",
    number3: 30,
    sub_title3: "Tecnolog\u00EDas",
  },
  services: {
    heading: "Nuestros Servicios",
    heading1: "Cloud Computing",
    text1: `Somos especialistas en dise\u00F1ar y desarrollar soluciones
      100% en la nube. Te guiamos y asesoramos en la
      migraci\u00F3n, modernizaci\u00F3n de aplicaciones,
      optimizaci\u00F3n de costos e integraciones con tus
      sistemas actuales.`,
    heading2: "Soluciones CRM",
    text2: `Brindamos la mejor soluci\u00F3n automatizada de fuerza
      de ventas y CRM en el mercado, lo que te permitir\u00E1
      centrarte en lo m\u00E1s importante: Tu Cliente.`,
    heading3: "Marketing Inteligente",
    text3: `Creamos experiencias \u00FAnicas y personalizadas a tus
      clientes para enviar el mensaje adecuado en el momento
      oportuno.`,
    heading4: "Robotic Process Automation",
    text4: `Automatizamos procesos mediante implementaci\u00F3n de
      bots dise\u00F1ados para integrar diferentes aplicaciones,
      aumentar la eficiencia, precisi\u00F3n y productividad
      en tus procesos de negocios.`,
    heading5: "Cient\u00EDficos de Datos",
    text5: `Contamos con un equipo de expertos encargados de maximizar
      los beneficios de la big data en tu empresa y presentar
      resultados con el fin de optimizar la toma de decisiones
      de tu negocio.`,
    heading6: "Desarrollo de Aplicaciones",
    text6: `Dise\u00F1amos y desarrollamos aplicaciones web y
      m\u00F3viles integrando diferentes tecnolog\u00EDas de
      vanguardia que permiten a tus clientes tener la mejor
      experiencia de usuario.`,
    heading7: "Almacenamiento de Documentos",
    text7: `Indexamos y almacenamos los documentos de tu empresa para
      un r\u00E1pido y f\u00E1cil acceso a lo que necesitas.
      Agiliza los procesos y ahorra espacio.`,
  },
  partners: { heading: "Nuestros Partners" },
  technologies: {
    heading: "<0>Tecnolog\u00EDas</0> en las que nos especializamos",
  },
  testimonials: {
    heading: "<0>Opiniones</0> de nuestros clientes",
    text0:
      "Nos brindaron soporte adecuado, preciso e inteligente, permitiendo fortalecer estrategias comerciales, ventas y servicio al cliente.",
    profession0: "Sub Gerente de Proyectos Digitales - LAFISE",
    text1:
      "Sales Cloud nos brinda en detalle todos los productos que el cliente tiene bajo su cuenta, y ayuda al supervisor a coordinar con todos.",
    profession1: "Analista de Recuperaciones - Banco",
    text2:
      "El equipo de desarrollo ha sido excelente, han logrado entender la necesidad de atenci\u00F3n y urgencia de nuestros requerimientos en l\u00EDnea.",
    profession2: "Supervisor de Call Center - Aseguradora",
  },
  apigateway: {
    title: "<0>Amazon</0> API Gateway",
    subText: "Servicio autoescalable para la creaci\u00F3n y administraci\u00F3n de APIs",
    paragraph1:
      "Amazon API Gateway otorga la facilidad de desarrollar, desplegar y mantener nuestras APIs de manera \u00E1gil y segura ya que cuenta con diferentes medidas de autenticaci\u00F3n y autorizaci\u00F3n que te permiten controlar de manera granular el acceso a los recursos.",
    paragraph2:
      "Tambi\u00E9n permite publicar nuevos microservicios con bajo costo y de manera r\u00E1pida sin preocuparse del mantenimiento y seguridad de los servidores, enfocando tu fuerza de trabajo en el desarrollo. Ya que proporciona la capacidad y la flexibilidad para el procesamiento de grandes cargas de trabajo escalando de forma autom\u00E1tica garantizando respuestas a todos tus clientes.",
    subtitle: "Beneficios",
    benefit1: "F\u00E1cil integraci\u00F3n con APIs externas o de terceros",
    benefit2: "Monitoreo y recolecci\u00F3n de estad\u00EDsticas de uso",
    benefit3: "Comprensi\u00F3n y desarrollo sencillo para optimizaci\u00F3n del tiempo",
    benefit4: "Disminuye los tiempo de desarrollo e implantaci\u00F3n",
    benefit5: "Facilita la administraci\u00F3n y autenticaci\u00F3n",
    benefit6: "Se acopla a las necesidades de tu empresa",
  },
  contact: {
    question: "¿Tienes un proyecto en mente?",
    form: {
      heading: "Cont\u00E1ctanos",
      placeholder1: "Nombre (Requerido)",
      placeholder2: "Apellido (Requerido)",
      placeholder3: "Tel\u00E9fono (Opcional)",
      placeholder4: "Correo Electr\u00F3nico (Requerido)",
      placeholder5: "Asunto (Opcional)",
      placeholder6: "Descripci\u00F3n (Requerido)",
      placeholder7: "Compa\u00F1\u00EDa (Opcional)",
      button: "Enviar",
      response1: "Informaci\u00F3n de contacto enviada 😎",
      title: "¡Algo ha salio mal! 😓",
      response2: "Por favor intenta nuevamente dentro de unos minutos 😬",
    },
    title: "Mapa puntos de presencia",
    title1: "Correo Electr\u00F3nico",
    email: "info@sistematica.io",
    title2: "Tel\u00E9fono",
    telephone: "+(505)2223-3660",
    title3: "Direcci\u00F3n",
    link:
      "https://maps.google.com/maps?ll=12.1246171,-86.2678787&z=17&t=m&hl=en&gl=US&mapclient=embed&q=Sistematica%20Internacional",
    address: `Km 4 carretera a Masaya, contiguo al Ministerio
      P\u00FAblico
      <1 />
      Managua, Nicaragua.`,
  },
};

export default es;
