import React, { useCallback, useState /* , { useCallback, useEffect, useState } */ } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import Swal from "sweetalert2";

// #region Styled Components"
const Separator = styled.div`
  height: 5px;
  width: 50px;
  background-color: #2ba0ea;
  margin-bottom: 30px;
  margin-left: 6px;
`;

const FormContact = styled.form`
  padding: 40px;
  width: 100%;
`;

const Heading = styled.h2`
  font-size: 70px;
  font-family: Teko;
  color: #fff;
`;

const InputElement = styled.div`
  margin: 20px 0;
`;

const ErrorInputAnimation = keyframes`
            0% {
              border-bottom: 1px solid #111;
            }
            100% {
              border-bottom: 1px solid #ff0000;
            }
        `;

const Input = styled.input`
  width: 100%;
  background-color: #071c2b;
  border: none;
  border-bottom: 1px solid #2ba0ea;
  padding: 10px 5px;
  border-radius: 0;
  color: #fff;
  transition: 0.5s;
  &:focus {
    border-bottom: 1px solid #f4d22a;
    outline: none;
  }
  &.error {
    animation: ${ErrorInputAnimation} 1s forwards;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  background-color: #071c2b;
  border: none;
  border-bottom: 1px solid #2ba0ea;
  padding: 10px 5px;
  border-radius: 0;
  resize: none;
  color: rgb(255, 255, 255);
  transition: all 0.5s ease 0s;
  min-height: 100px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 100px;
  &:focus {
    border-bottom: 1px solid #f4d22a;
    outline: none;
  }
  &.error {
    animation: ${ErrorInputAnimation} 1s forwards;
  }
`;

const Submit = styled.button`
  display: block;
  height: 50px;
  width: 186px;
  position: relative;
  border: none;
  overflow: hidden;
  transition: 0.5s;
  &::after {
    position: absolute;
    content: "";
    display: inline-block;
    background: linear-gradient(
      45deg,
      rgba(07, 28, 43, 1) 0%,
      rgba(28, 50, 88, 1) 35%,
      rgba(43, 160, 234, 1) 100%
    );
    height: 100%;
    width: 140%;
    top: 0;
    left: 0;
    transition: 0.5s;
  }
  &:hover {
    &::after {
      transform: translateX(-20%);
    }
  }
  span {
    position: relative;
    top: 0;
    color: #fff;
    z-index: 10;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
// #endregion

const ContactForm = () => {
  // const [, updateState] = useState();
  // const forceUpdate = useCallback(() => updateState({}), []);
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});

  const updateErrors = useCallback((stateUpdates) => {
    setErrors((prevState) => {
      return { ...prevState, ...{ stateUpdates } };
    });
  });

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   formState: { errors, isSubmitting },
  // } = useForm({
  //   mode: "onChange",
  //   defaultValues: {
  //     firstName: "",
  //     lastName: "",
  //     phone: "",
  //     email: "",
  //     issue: "",
  //     description: "",
  //   },
  // });

  // useEffect(() => {
  //   console.log("updating")
  //   forceUpdate();
  // }, [forceUpdate]);

  const formSubmit = async (data) => {
    // console.log(data);
    const { firstName, lastName, phone, email, issue, description } = data;

    try {
      // throw new Error("Error provocado para pruebas");

      const response = await fetch(
        "https://6zr2wzzib0.execute-api.us-east-1.amazonaws.com/dev/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "2wevgoov2o2Xh8RvGxF0c3ljdcl2rphtaIZQC8Mh",
          },
          body: JSON.stringify({ firstName, lastName, phone, email, issue, description }),
        }
      );
      if (response.status === 200) {
        Swal.fire({
          backdrop: "rgba(07, 28, 43, 0.85)",
          background: "#1C3258",
          color: "#2ba0ea",
          icon: "success",
          iconColor: "#f4d22a",
          titleText: t("contact.form.response1"),
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          backdrop: "rgba(07, 28, 43, .85)",
          background: "#1C3258",
          color: "#2ba0ea",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#071c2b",
          icon: "error",
          titleText: t("contact.form.title"),
          html:
            (response.text() ? `<p>Error: ${response.text()}</p>` : "") +
            `<p>${t("contact.form.response2")}</p>`,
        });
      }
    } catch (error) {
      Swal.fire({
        backdrop: "rgba(07, 28, 43, .85)",
        background: "#1C3258",
        color: "#2ba0ea",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#071c2b",
        icon: "error",
        titleText: t("contact.form.title"),
        html:
          (error.message ? `<p>Error: ${error.message}</p>` : "") +
          `<p>${t("contact.form.response2")}</p>`,
      });
    }
    reset();
  };

  return (
    <FormContact
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D80000000aSi3"
      method="POST"
    >
      <input type="hidden" name="oid" value="00D80000000aSi3" />
      <input type="hidden" name="retURL" value="https://sistematica.io/?thankU=" />
      {/* <input type="hidden" name="debug" value="1" />
      <input type="hidden" name="debugEmail" value="ksomarriba@sistematica.io" /> */}
      <Heading>{t("contact.form.heading")}</Heading>
      <Separator />
      <InputElement>
        <Input
          /* {...register("firstName", { required: true, pattern: /^[A-Z ]+$/i, maxLength: 100 })} */
          name="first_name"
          required
          pattern="^[A-Za-z ]+$"
          maxLength={100}
          className={`firstName ${errors.firstName ? "error" : ""}`}
          placeholder={t("contact.form.placeholder1")}
        />
      </InputElement>
      <InputElement>
        <Input
          /* {...register("lastName", { required: true, pattern: /^[A-Z ]+$/i, maxLength: 100 })} */
          name="last_name"
          required
          pattern="^[A-Za-z ]+$"
          maxLength={100}
          className={`lastName ${errors.lastName ? "error" : ""}`}
          placeholder={t("contact.form.placeholder2")}
        />
      </InputElement>
      <InputElement>
        <Input
          name="company"
          pattern="^[\w ]+$"
          maxLength={100}
          placeholder={t("contact.form.placeholder7")}
          className={`description ${errors.description ? "error" : ""}`}
        />
      </InputElement>
      <InputElement>
        <Input
          /* {...register("phone", { pattern: /^\+?\d+$/i, maxLength: 100 })} */
          name="phone"
          type="tel"
          pattern="^\+?\d+$"
          maxLength={100}
          className={`phone ${errors.phone ? "error" : ""}`}
          placeholder={t("contact.form.placeholder3")}
        />
      </InputElement>
      <InputElement>
        <Input
          /* {...register("email", {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            maxLength: 100,
          })} */
          name="email"
          type="email"
          required
          pattern="^[\-\w\d+%.]+@[\-\w\d.]+\.[\w]{2,}$"
          maxLength={100}
          className={`email ${errors.email ? "error" : ""}`}
          placeholder={t("contact.form.placeholder4")}
        />
      </InputElement>
      <InputElement>
        <Input
          /* {...register("issue", { maxLength: 100 })} */
          name="00NRc00000EhSFV"
          maxLength={100}
          className={`issue ${errors.issue ? "error" : ""}`}
          placeholder={t("contact.form.placeholder5")}
        />
      </InputElement>
      <InputElement>
        <Textarea
          /* {...register("description", { required: true, maxLength: 300 })} */
          name="description"
          required
          maxLength={300}
          placeholder={t("contact.form.placeholder6")}
          className={`description ${errors.description ? "error" : ""}`}
        />
      </InputElement>
      <Submit type="submit" /* disabled={isSubmitting} onClick={handleSubmit(formSubmit)} */>
        <span>{t("contact.form.button")}</span>
      </Submit>
    </FormContact>
  );
};

export default React.memo(ContactForm);
