import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";

import AnimationContainer from "components/animation-container";
import ContactForm from "./parts/ContactForm.js";
import { Trans, withTranslation } from "react-i18next";

import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../redux/slices/page";

class Contact extends React.Component {
  componentDidMount() {
    this.forceUpdate();
  }

  render() {
    const { t, currentPage } = this.props;

    const QuestionSection = styled.section`
      position: relative;
      overflow: hidden;
      background-color: #071c2b;
      padding: 50px 0;
      text-align: center;
    `;

    const Question = styled.h2`
      font-size: 60px;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 20px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 35px;
      }
    `;

    const Section = styled.section`
      position: relative;
      overflow: hidden;
      background-color: #071c2b;
    `;

    const FormRow = styled(Row)`
      background-color: #1c3258;
    `;

    const ContactCol = styled(Col)`
      min-height: 820px;
      max-height: 820px;
      padding: 0;
      display: flex;
      align-items: center;
    `;

    const Map = styled.iframe`
      border: none;
      height: 100%;
      width: 100%;
    `;

    const IconRow = styled(Row)`
      margin-top: 150px;
    `;

    const IconCol = styled(Col)`
      @media (max-width: 500px) {
        margin-bottom: 140px;
      }
    `;

    const IconContainer = styled.div`
      width: 150px;
      height: 150px;
      margin: auto;
      padding: 40px;
      text-align: center;
      position: relative;
      bottom: 75px;
      background-color: #1c3258;
      border-radius: 150px;
      transition: 0.5s;
    `;

    const InfoPart = styled.div`
      min-height: 250px;
      background-color: #071c2b;
      border: 2px solid #444;
      &:hover ${IconContainer} {
        background-color: #2ba0ea;
      }
    `;
    const Icon = styled.img`
      height: 70px;
      width: 70px;
      object-fit: contain;
    `;

    const InfoTitle = styled.h4`
      font-size: 35px;
      color: #fff;
      font-family: Teko;
      text-align: center;
    `;

    const Info = styled.div`
      position: relative;
      bottom: 30px;
    `;

    const InfoLinkContainer = styled.div`
      text-align: center;
    `;

    const InfoLink = styled.a`
      color: #2ba0ea;
      transition: 0.5s;
      &:hover {
        color: #f4d22a;
        text-decoration: none;
      }
    `;

    return (
      <>
        <QuestionSection hidden={currentPage === "main"}>
          <Question>{t("contact.question")}</Question>
        </QuestionSection>
        <Section
          style={{
            padding: currentPage === "main" ? "100px 0" : "0 0 100px",
          }}
          id="contact"
        >
          <Container>
            <AnimationContainer animation="fadeIn">
              <FormRow>
                <ContactCol md={6}>
                  <ContactForm />
                </ContactCol>
                <ContactCol md={6}>
                  <Map title={t("contact.title")} src={this.props.map.publicURL /* pointsMap */} />
                </ContactCol>
              </FormRow>
            </AnimationContainer>
            <IconRow>
              <IconCol md={4}>
                <AnimationContainer animation="fadeIn" delay={500}>
                  <InfoPart>
                    <IconContainer>
                      <Icon src={this.props.contact1.childImageSharp.fluid.src} alt="Correo" />
                    </IconContainer>
                    <Info>
                      <InfoTitle>{t("contact.title1")}</InfoTitle>
                      <InfoLinkContainer>
                        <InfoLink href={`mailto:${t("contact.email")}`}>
                          {t("contact.email")}
                          <br />
                          <br />
                          <br />
                        </InfoLink>
                      </InfoLinkContainer>
                    </Info>
                  </InfoPart>
                </AnimationContainer>
              </IconCol>
              <IconCol md={4}>
                <AnimationContainer animation="fadeIn" delay={1000}>
                  <InfoPart>
                    <IconContainer>
                      <Icon
                        src={this.props.contact2.childImageSharp.fluid.src}
                        alt="Tel&eacute;fono"
                      />
                    </IconContainer>
                    <Info>
                      <InfoTitle>{t("contact.title2")}</InfoTitle>
                      <InfoLinkContainer>
                        <InfoLink href={`tel:${t("contact.telephone")}`}>
                          {t("contact.telephone")}
                          <br />
                          <br />
                          <br />
                        </InfoLink>
                      </InfoLinkContainer>
                    </Info>
                  </InfoPart>
                </AnimationContainer>
              </IconCol>
              <IconCol md={4}>
                <AnimationContainer animation="fadeIn" delay={1500}>
                  <InfoPart>
                    <IconContainer>
                      <Icon src={this.props.contact3.childImageSharp.fluid.src} alt="Pin" />
                    </IconContainer>
                    <Info>
                      <InfoTitle>{t("contact.title3")}</InfoTitle>
                      <InfoLinkContainer>
                        <InfoLink target="_blank" href={t("contact.link")}>
                          <Trans i18nKey={"contact.address"}>
                            Km 4 carretera a Masaya, contiguo al Ministerio P&uacute;blico
                            <br />
                            Managua, Nicaragua.
                          </Trans>
                        </InfoLink>
                      </InfoLinkContainer>
                    </Info>
                  </InfoPart>
                </AnimationContainer>
              </IconCol>
            </IconRow>
          </Container>
        </Section>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()((props) => (
    <StaticQuery
      query={graphql`
        query {
          map: file(relativePath: { eq: "points.html" }, sourceInstanceName: { eq: "map" }) {
            publicURL
          }
          contact1: file(relativePath: { eq: "icons/contact1.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                src
              }
            }
          }
          contact2: file(relativePath: { eq: "icons/contact2.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                src
              }
            }
          }
          contact3: file(relativePath: { eq: "icons/contact3.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                src
              }
            }
          }
        }
      `}
      render={({ map, contact1, contact2, contact3 }) => (
        <Contact map={map} contact1={contact1} contact2={contact2} contact3={contact3} {...props} />
      )}
    />
  ))
);
