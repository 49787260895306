import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

class Footer extends React.Component {
  render() {
    const FooterMain = styled.div`
      background-color: #111;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const SocialIcons = styled.div`
      .social_icon {
        font-size: 20px;
        color: #555;
        margin: 0 5px;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
          color: #04e5e5;
        }
        @media (min-width: 961px) {
          font-size: 30px;
        }
      }
    `;

    return (
      <FooterMain>
        <SocialIcons>
          <FontAwesomeIcon
            icon={faFacebook}
            className="social_icon"
            onClick={() =>
              window.open("https://www.facebook.com/sistematicaInternacional/")
            }
          />
          <FontAwesomeIcon
            icon={faInstagram}
            className="social_icon"
            onClick={() => window.open("https://www.instagram.com")}
          />
          <FontAwesomeIcon
            icon={faYoutube}
            className="social_icon"
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCxr8X6nJWA77OPLAcxf9pCg/featured"
              )
            }
          />
          <FontAwesomeIcon
            icon={faLinkedin}
            className="social_icon"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/sistematica-internacional-inc/"
              )
            }
          />
        </SocialIcons>
      </FooterMain>
    );
  }
}
export default Footer;
