import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import PartnerItem from "sections/partners/parts/PartnerItem.js";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import AnimatedHeading from "components/animated-heading";
// import AnimationContainer from "components/animation-container";

import { withTranslation } from "react-i18next";

class Partners extends React.Component {
  componentDidMount() {
    this.forceUpdate();
  }

  render() {
    const { t } = this.props;

    const Section = styled.section`
      background-color: #1c3258;
      padding: 100px 0;
    `;
    const PortfolioContainer = styled.div`
            padding: 0 50px;
            @media (max-width:767px) {
              padding: 0 50px;
            }
            .slick-slide {
              display: block;
              margin: 0px 0 70px 0px;
            }
            .slick-dots {
              bottom: 0;
              li button:before,.slick-dots li.slick-active button:before {
                color: #2ba0ea;
              }
            }
          }
        `;

    // const settings = {
    //   dots: false,
    //   swipe: false,
    //   infinite: false,
    //   // speed: 500,
    //   slidesToShow: 3,
    //   slidesToScroll: 3,
    //   // arrows: false,
    //   // autoplay: true,
    //   // autoplaySpeed: 2000,
    //   // loop: true,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 3,
    //       },
    //     },
    //     {
    //       breakpoint: 600,
    //       settings: {
    //         slidesToShow: 2,
    //         initialSlide: 2,
    //       },
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //       },
    //     },
    //   ],
    // };

    return (
      <Section id="partners">
        <Col md={12} style={{ padding: 0 }}>
          <Container>
            <AnimatedHeading text={t("partners.heading")} />
          </Container>
          <PortfolioContainer>
            {/* <AnimationContainer animation="fadeIn">
              <Slider {...settings}>{this.portfolio()}</Slider>
            </AnimationContainer> */}
            <Row>{this.portfolio()}</Row>
          </PortfolioContainer>
        </Col>
      </Section>
    );
  }

  portfolio() {
    const { items } = this.props;

    return items.map((value, index) => {
      return (
        <Col md={4} key={index}>
          <PartnerItem
            key={index}
            index={index}
            image={value.content.frontmatter.image.childImageSharp.fluid.src}
            text={value.content.frontmatter.title}
            category={value.content.frontmatter.category}
            link={value.content.frontmatter.link}
            // type="slider"
          />
        </Col>
      );
    });
  }
}

export default withTranslation()((props) => (
  <StaticQuery
    query={graphql`
      query {
        items: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(partners)/" } }
          sort: { fields: [frontmatter___id], order: ASC }
          limit: 9
        ) {
          edges {
            content: node {
              frontmatter {
                id
                title
                category
                link
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ items }) => <Partners items={items.edges} {...props} />}
  />
));
