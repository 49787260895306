import React, { memo, useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { selectPage, setCurrentPage } from "../../redux/slices/page";

var scrollToElement = require("scroll-to-element");

const NavbarWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 20px 0;
  z-index: 100;
  &.sticky {
    position: fixed;
    background-color: rgba(07, 28, 43, 0.8);
    padding: 0 0;
    @media (max-width: 500px) {
      padding: 20px 0;
    }
  }
`;

const NavbarContainer = styled(Container)`
  display: flex;
  position: relative;
  @media (max-width: 500px) {
    display: block;
    padding: 0;
  }
`;

const Nav = styled.nav`
  flex: 0 0 80%;
  max-width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 500px) {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
    background-color: rgba(07, 28, 43, 0.8);
    margin-top: 20px;
    &.hidden_mobile {
      display: none;
    }
  }
`;

const LogoWrapper = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 500px) {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }
`;

const Logo = styled.img`
  height: 40px;
  @media (max-width: 1023px) {
    height: 30px;
  }
`;

const NavInner = styled.div`
  justify-content: flex-end;
`;

const Toggler = styled.button`
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  @media (min-width: 500px) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  text-align: center;
  padding: 0px;
  z-index: 101;
  background-color: rgba(07, 28, 43, 0.8);
`;

const DropItem = styled.button`
  background: none;
  padding: 0px;
  border: none;
  color: #fff;
  font-weight: 500;
  margin: 5px 0px;
  transition: 0.5s;
  &:hover {
    color: #2ba0ea;
    background: none;
  }
  &:focus {
    outline: none;
  }
  @media (min-width: 501px) and (max-width: 1023px) {
    font-size: 11px;
    margin: 2px;
  }
`;

const NavItem = styled.button`
  background: none;
  padding: 0px;
  border: none;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  margin: 5px 5px;
  transition: 0.5s;
  &:hover {
    color: #2ba0ea;
    background: none;
  }
  &:focus {
    outline: none;
  }
  @media (min-width: 501px) and (max-width: 1023px) {
    font-size: 11px;
    margin: 2px;
  }
`;

const Navbar = (props) => {
  const dispatch = useDispatch();

  const { currentPage } = useSelector(selectPage);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sticky, setSticky] = useState(false);
  // eslint-disable-next-line
  const [sections, setSections] = useState(
    props.sections
      ? props.sections
      : [
          "home",
          "about",
          "services",
          "partners",
          /* "technologies",
        "team", */
          "solutions",
          "contact",
          "account",
          "language",
        ]
  );

  const { t, i18n } = useTranslation();

  const handleScroll = (event) => {
    if (window.pageYOffset >= 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("main"));
  }, [dispatch]);

  const collapseNav = () => {
    if (!isCollapsed) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  };

  const navigate = (id) => {
    if (props.scroll) {
      const el = document.getElementById(id);
      scrollToElement(el, {
        offset: 0,
        ease: "in-out-expo",
        duration: 2000,
      });
    } else {
      window.location.href = `./#${id}`;
    }
  };

  const navItems = () => {
    const languages = {
      es: { nativeName: "Espa\u00F1ol" },
      en: { nativeName: "English" },
    };

    return sections.map((value, index) => {
      if (index === sections.length - 1) {
        return (
          <Dropdown key={index}>
            <Dropdown.Toggle as={NavItem} id="dropdown-language">
              <FontAwesomeIcon icon={faGlobeAmericas} /> {t(`menu.${value}`)} 
            </Dropdown.Toggle>
            <Dropdown.Menu as={MenuWrapper}>
              {Object.keys(languages).map((lang) => {
                if (t(`menu.${value}`) !== languages[lang].nativeName) {
                  return (
                    <Dropdown.Item
                      key={lang}
                      as={DropItem}
                      onClick={() => {
                        i18n.changeLanguage(lang);
                      }}
                    >
                      {languages[lang].nativeName}
                    </Dropdown.Item>
                  );
                }
                return null;
              })}
            </Dropdown.Menu>
          </Dropdown>
        );
      }
      if (index === sections.length - 2) {
        return (
          <NavItem
            key={index}
            onClick={() =>
              window.open(
                "https://intercambiopropiedades.secure.force.com/AID_users"
              )
            }
          >
            {t(`menu.${value}`)}
          </NavItem>
        );
      }
      if (index === sections.length - 3) {
        return (
          <NavItem
            key={index}
            onClick={() => {
              navigate(value);
            }}
          >
            {t(`menu.${value}`)}
          </NavItem>
        );
      }
      if (index === sections.length - 4) {
        return (
          <Dropdown key={index}>
            <Dropdown.Toggle as={NavItem} id="dropdown-services">
              {t(`menu.${value}`)}
            </Dropdown.Toggle>

            <Dropdown.Menu as={MenuWrapper}>
              {currentPage !== "landing" ? (
                <Link to="/api-gateway">
                  <Dropdown.Item as={DropItem}>
                    {t(`menu.apigateway`)}
                  </Dropdown.Item>
                </Link>
              ) : (
                <Dropdown.Item
                  key={index}
                  as={DropItem}
                  onClick={() => {
                    navigate("apigateway");
                  }}
                >
                  {t(`menu.apigateway`)}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      }
      if (index === 0) {
        return currentPage !== "main" ? (
          <Link to="/" key={index}>
            <NavItem>{t(`menu.${value}`)}</NavItem>
          </Link>
        ) : (
          <NavItem
            key={index}
            onClick={() => {
              navigate(value);
            }}
          >
            {t(`menu.${value}`)}
          </NavItem>
        );
      }
      return currentPage !== "main" ? (
        <Link to="/" key={index}>
          <NavItem
            onClick={() => {
              setTimeout(() => {
                navigate(value);
              }, 1000);
            }}
          >
            {t(`menu.${value}`)}
          </NavItem>
        </Link>
      ) : (
        <NavItem
          key={index}
          onClick={() => {
            navigate(value);
          }}
        >
          {t(`menu.${value}`)}
        </NavItem>
      );
    });
  };

  return (
    <NavbarWrapper className={`header${sticky === true ? " sticky" : ""}`}>
      <NavbarContainer>
        {currentPage === "main" ? (
          <LogoWrapper className="logo">
            <Logo
              src="/img/logo.png"
              alt="Sistematica"
              onClick={() => navigate(sections[0])}
            />
          </LogoWrapper>
        ) : (
          <LogoWrapper className="logo">
            <Link to="/">
              <Logo src="/img/logo.png" alt="Sistematica" />
            </Link>
          </LogoWrapper>
        )}
        <Toggler
          onClick={() => collapseNav()}
          className="navbar-toggler navbar-toggler-right"
        >
          <FontAwesomeIcon icon={faBars} className="bars" />
        </Toggler>
        <Nav
          className={`navbar navbar-expand-sm ${
            isCollapsed === true ? "expand" : "hidden_mobile"
          }`}
        >
          <NavInner
            className={`navbar-collapse collapse ${
              isCollapsed === true ? "show" : ""
            }`}
          >
            <div className="navbar-nav" style={{ alignItems: "center" }}>
              {navItems()}
            </div>
          </NavInner>
        </Nav>
      </NavbarContainer>
    </NavbarWrapper>
  );
};

export default memo(Navbar);
