import React, { useEffect } from "react";
import PageRevealer from "components/page-revealer";
import Navbar from "components/navbar";
import Footer from "components/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "scss/abstract.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectPage, setFirstLoad } from "../../redux/slices/page";

const Layout = (props) => {
  const dispatch = useDispatch();
  const { firstLoad } = useSelector(selectPage);
  const { children } = props;

  useEffect(() => {
    return () => {
      dispatch(setFirstLoad());
    };
  }, [dispatch]);

  return (
    <div id="main">
      <Navbar scroll={props.isHome} sections={props.sections} />
      {firstLoad && <PageRevealer />}
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
