const shuffleArray = (array) => {
  let arrayLenght = array.length;
  let random;
  let temp;

  while (arrayLenght) {
    random = Math.floor(Math.random() * arrayLenght--);

    temp = array[arrayLenght];
    array[arrayLenght] = array[random];
    array[random] = temp;
  }
  return array;
};

export default shuffleArray;
