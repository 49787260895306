import React from "react";

import { Row, Col, Container } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import shuffleArray from "../../components/shuffle-array";

import { withTranslation } from "react-i18next";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
    };
  }

  updateDimensions = () => {
    if (this.state.height !== window.innerHeight) {
      this.setState({ height: window.innerHeight });
    }
    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth });
    }
  };

  componentDidMount() {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  typewriterArray = () => {
    const { t } = this.props;
    const array = [];
    let i = 0;
    while (i < 9) {
      array.push(t(`home.typewriter.word${i++}`));
    }
    return array;
  };

  render() {
    const { t } = this.props;
    const typewriterArray = this.typewriterArray();

    const Section = styled.section`
      position: relative;
      .particles {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      background-image: url(${this.props.background.childImageSharp.fluid.src});
      background-size: cover;
      background-repeat: no-repeat;
    `;

    const Overlay = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(07, 28, 43, 0.8);
    `;

    const Heading1 = styled.h1`
      font-size: 80px;
      font-family: Teko;
      line-height: 40px;
      color: #fff;
      font-weight: 400;
      text-transform: uppercase;
      @media (min-width: 768px) {
        margin-left: 4px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 40px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 20px;
      }
    `;

    /* background: -webkit-linear-gradient(left, #2ba0ea, #1c3258);
      background: -o-linear-gradient(right, #2ba0ea, #1c3258);
      background: -moz-linear-gradient(right, #2ba0ea, #1c3258);
      background: linear-gradient(to right, #2ba0ea, #1c3258); */
    const Heading2 = styled.h2`
      font-size: 120px;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      background: #2ba0ea;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 40px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 70px;
      }
    `;

    const Type = styled.div`
      font-size: 50px;
      line-height: 50px;
      color: #fff;
      text-transform: uppercase;
      @media (min-width: 768px) {
        margin-left: 6px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 30px;
        line-height: 20px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 20px;
      }
      span {
        font-family: Teko;
      }
    `;
    const Content = styled.div`
      position: relative;
      z-index: 10;
    `;

    const Separator = styled.div`
      height: 5px;
      width: 50px;
      background-color: #2ba0ea;
      margin-bottom: 30px;
      @media (min-width: 768px) {
        margin-left: 6px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
    `;

    const BottomContent = styled.div`
      position: absolute;
      width: 100%;
      bottom: -14%;
      z-index: 2;
      @media (min-width: 1024px) and (max-width: 1400px) {
        bottom: -35%;
      }
      @media (max-width: 767px) {
        position: relative;
        bottom: -14%;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        bottom: -35%;
      }
    `;

    const PillerCol = styled(Col)`
      padding: 0;
      border-right: #444;
      &.no-border {
        border-right: none;
      }
      @media (max-width: 500px) {
        border-right: none;
      }
    `;

    const Piller = styled.div`
      min-height: 300px;
      background-color: #071c2b;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      flex-direction: column;
      &:hover {
        background-color: #1c3258;
      }
    `;
    const PillerContent = styled.div`
      padding: 40px;
      color: #fff;
    `;
    const PillerHeading = styled.h4`
      font-size: 30px;
      font-weight: 400;
      font-family: Teko;
    `;
    const PillerSeparator = styled.div`
      height: 5px;
      width: 50px;
      background-color: #2ba0ea;
      margin-bottom: 10px;
    `;

    const PillerIcon = styled.div`
      margin-bottom: 20px;
      img {
        max-height: 70px;
      }
    `;

    const PillerText = styled.p`
      font-size: 14px;
      font-weight: 300;
      color: #c5c5c5;
      @media (min-width: 961px) {
        font-size: 16px;
      }
    `;

    return (
      <Section id="home">
        <Overlay
          style={{
            height: `${this.state.width > 500 ? this.state.height : 350}px`,
          }}
        >
          <Container>
            <Content>
              <Heading1>{t("home.sub_title")}</Heading1>
              <Heading2>Sistematica</Heading2>
              <Separator />
              <Type>
                <Typewriter
                  options={{
                    strings: shuffleArray(typewriterArray),
                    autoStart: true,
                    loop: true,
                    delay: 50,
                    deleteSpeed: 2,
                  }}
                />
              </Type>
            </Content>
          </Container>
        </Overlay>
        <BottomContent>
          <Container>
            <Row>
              <PillerCol
                md={4}
                style={{
                  borderRight:
                    this.state.width > 500 ? "1px solid #444" : "none",
                }}
              >
                <Piller>
                  <PillerContent>
                    <PillerIcon>
                      <img
                        src={this.props.piller1.childImageSharp.fluid.src}
                        alt="Experiencia"
                      />
                    </PillerIcon>
                    <PillerHeading>{t("home.piller1")}</PillerHeading>
                    <PillerSeparator />
                    <PillerText>{t("home.content1")}</PillerText>
                  </PillerContent>
                </Piller>
              </PillerCol>
              <PillerCol
                md={4}
                style={{
                  borderRight:
                    this.state.width > 500 ? "1px solid #444" : "none",
                }}
              >
                <Piller>
                  <PillerContent>
                    <PillerIcon>
                      <img
                        src={this.props.piller2.childImageSharp.fluid.src}
                        alt="Agilidad"
                      />
                    </PillerIcon>
                    <PillerHeading>{t("home.piller2")}</PillerHeading>
                    <PillerSeparator />
                    <PillerText>{t("home.content2")}</PillerText>
                  </PillerContent>
                </Piller>
              </PillerCol>
              <PillerCol md={4}>
                <Piller>
                  <PillerContent>
                    <PillerIcon>
                      <img
                        src={this.props.piller3.childImageSharp.fluid.src}
                        alt="Calidad"
                      />
                    </PillerIcon>
                    <PillerHeading>{t("home.piller3")}</PillerHeading>
                    <PillerSeparator />
                    <PillerText>{t("home.content3")}</PillerText>
                  </PillerContent>
                </Piller>
              </PillerCol>
            </Row>
          </Container>
        </BottomContent>
      </Section>
    );
  }
}

export default withTranslation()((props) => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "homebackground.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              src
            }
          }
        }
        piller1: file(relativePath: { eq: "icons/piller1.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        piller2: file(relativePath: { eq: "icons/piller2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        piller3: file(relativePath: { eq: "icons/piller3.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    `}
    render={({ background, piller1, piller2, piller3 }) => (
      <Home
        background={background}
        piller1={piller1}
        piller2={piller2}
        piller3={piller3}
        {...props}
      />
    )}
  />
));
