const en = {
  menu: {
    home: "Home",
    about: "About us",
    services: "Services",
    solutions: "Solutions",
    partners: "Partners",
    contact: "Contact",
    account: "My Account",
    language: "English",
    apigateway: "API Design",
  },
  home: {
    sub_title: "Consultants",
    typewriter: {
      word0: "Chatbots",
      word1: "Comprehensive Solutions",
      word2: "Robotics",
      word3: "Agility",
      word4: "Security",
      word5: "Cloud Computing",
      word6: "Business Intelligence",
      word7: "Mobile Applications",
      word8: "Artificial intelligence",
    },
    piller1: "Experience",
    content1:
      "We have a talented team of certified experts in different cutting-edge technologies.",
    piller2: "Agility",
    content2:
      "Our experience and methodology allow us to provide you with comprehensive solutions quickly and effectively.",
    piller3: "Quality",
    content3:
      "Thanks to our continuous learning and improvement processes, we provide solutions of the best quality.",
  },
  about: {
    heading: "<0>Creators</0> of <2>Technological</2> Solutions",
    paragraph1: `Since our foundation in 1995, we have created relationships
      of trust and loyalty with our clients. We have more than 20 years of
      experience working with financial entities in the region and other important
      organizations. We offer solutions to simplify processes through various services.
      We develop web applications, we also index and store your physical documents.`,
    paragraph2: `We are oriented to satisfy the needs of your company through
      innovation and new technological trends, so that you can reduce your
      operating costs and increase your efficiency. We have a committed team,
      working with integrity and honesty, which has been part of our essence.
      We ensure the best service and quality in a personalized product, at the height of your company.`,
    number1: 80,
    sub_title1: "Certifications",
    number2: 25,
    sub_title2: "27 Years of Experience",
    number3: 30,
    sub_title3: "Technologies",
  },
  services: {
    heading: "Ours Services",
    heading1: "Cloud Computing",
    text1: `We are specialists in designing and developing 100% cloud
      solutions. We guide and advise you on migration, application
      modernization, cost optimization and integration with your current systems.`,
    heading2: "CRM Solutions",
    text2: `We provide the best automated sales force
      and CRM in the market, which will allow you to
      focus on the most important thing: Your Client.`,
    heading3: "Smart Marketing",
    text3: `We create unique and personalized experiences for your
      customers to send the right message at the right time.`,
    heading4: "Robotic Process Automation",
    text4: `We automate processes through the implementation of bots
      designed to integrate different applications, increase efficiency,
      precision and productivity in your business processes.`,
    heading5: "Data Scientists",
    text5: `
    We have a team of experts in charge of maximizing the benefits of
      big data in your company and presenting results in order to
      optimize decision-making in your business.`,
    heading6: "Application Development",
    text6: `We design and develop web and mobile applications integrating
      different cutting-edge technologies that allow your clients
      to have the best user experience.`,
    heading7: "Document Storage",
    text7: `We index and store your company documents for quick and easy
      access to what you need. Streamlines processes and saves space.`,
  },
  partners: { heading: "Ours Partners" },
  technologies: {
    heading: "<0>Technologies</0> in which we specialize",
  },
  testimonials: {
    heading: "<0>Opinions</0> of our clients",
    text0:
      "They gave us adequate, precise and intelligent support, allowing us to strengthen commercial strategies, sales and customer service.",
    profession0: "Deputy Manager of Digital Projects - LAFISE",
    text1:
      "Sales Cloud gives us in detail all the products that the client has under his account, and helps the supervisor to coordinate with all.",
    profession1: "Recovery Analyst - Bank",
    text2:
      "The development team has been excellent, they have managed to understand the need for attention and urgency of our online requirements.",
    profession2: "Call Center Supervisor - Insurance",
  },
  apigateway: {
    title: "<0>Amazon</0> API Gateway",
    subText: "Self-scaling service for the creation and administration of APIs",
    paragraph1:
      "Amazon API Gateway makes it easy to develop, deploy, and maintain our APIs in an agile and secure manner, since it has different authentication and authorization measures that allow you to control access to resources in a granular manner.",
    paragraph2:
      "It also allows you to publish new microservices at a low cost and quickly without worrying about the maintenance and security of the servers, focusing your workforce on development. Since it provides the capacity and flexibility to process large workloads, scaling automatically, guaranteeing answers to all your clients.",
    subtitle: "Benefits",
    benefit1: "Easy integration with external or third-party APIs",
    benefit2: "Monitoring and collection of usage statistics",
    benefit3: "Simple understanding and development for time optimization",
    benefit4: "Reduces development and implementation time",
    benefit5: "Facilitates administration and authentication",
    benefit6: "Adapts to the needs of your company",
  },
  contact: {
    question: "Do you have a project in mind?",
    form: {
      heading: "Contact us",
      placeholder1: "Name (Required)",
      placeholder2: "Lastname (Required)",
      placeholder3: "Telephone (Optional)",
      placeholder4: "Email (Required)",
      placeholder5: "Issue (Optional)",
      placeholder6: "Description (Required)",
      placeholder7: "Company (Optional)",
      button: "Send",
      response1: "Contact information sent 😎",
      title: "Something went wrong! 😓",
      response2: "Please try again in a few minutes 😬",
    },
    title: "Presence Points Map",
    title1: "Email",
    email: "info@sistematica.io",
    title2: "Telephone",
    telephone: "+(505)2223-3660",
    title3: "Address",
    link:
      "https://maps.google.com/maps?ll=12.1246171,-86.2678787&z=17&t=m&hl=en&gl=US&mapclient=embed&q=Sistematica%20Internacional",
    address: `Km 4 highway to Masaya, next to the Public Ministry
      <1 />
      Managua, Nicaragua.`,
  },
};

export default en;
